@import 'assets/scss/variables.scss';

.main-logo-icon {
  width: 1em;
  height: 1em;
  padding: 0em 0.3em 0.3em 0em;
}

.preview {
  float: right;
  font-style: italic;
  margin-right: 10px;
}

/* Refined for Siton */
.main-header {
  position: absolute;
  width: 100%;
  max-height: 100px;
  z-index: 1030;
  .logo {
    transition: width .3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 230px;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
    color: $theme-white;
    border-bottom: 0 solid transparent;
    img {
      padding: 0;
      object-fit: contain;
    }
    .logo-mini {
      display: none;
      img {
        max-width: 50px;
        max-height: 50px;
      }
    }
    .logo-lg {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      .logo-lg-version {
        height: 30px;
        font-size: 12px;
      }
      img {
        width: auto;
        height: 30px;
      }
    }
  }
  .logo:hover {
    text-decoration: none;
  }
  .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px;
  }
  .navbar {
    transition: margin-left .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 230px;
    border: none;
    min-height: 50px;
    border-radius: 0;
    .sidebar-toggle {
      color: #fff;
      &::before {
        content: none
      }
    }
    .navbar-custom-menu {
      float: right;
      .navbar-nav {
        .dropdown {
          a {
            color: #fff;
            background-color: inherit;
          }
          ul {
            li {
              a {
                svg {
                  width: 20px;
                  margin-right: 6px;
                }
                color: #333;
                &:hover {
                  background-color: #e1e3e9;
                }
              }
            }
          }
        }
        .dropdown:last-child > .dropdown-menu {
          left: auto;
        }
      }
    }
  }
  .notification-dropdown {
    position: absolute;
    right: 0;
    left: auto;
    top: 100%;
    z-index: 1000;
    float: left;
    min-width: 300px;
    padding: 0;
    // margin: 2px 0 0;
    // margin: 2px 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    max-height: 320px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    li {
      padding: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
      justify-content: space-between;
      cursor: pointer;
      &.notification-head {
        padding: 2px 5px 5px;
        position: sticky;
      }
      &.notification-none {
        padding: 5px 5px;
        border-bottom: none;
      }
      &.notification-content {
        &:hover {
          background-color: #f4f4f4;
        }
      }
      & > div {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        p {
          &.message {
            font-size: 14px;
            margin-bottom: 15px;
            margin-top: 10px;
          }
          &.duration {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
        &.isRead p {
          color: #A19F9D
        }
      }
    }
  }
  .notice-circle {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 15px;
    left: 20px;
    border-radius: 50%;
    background: red;
  }
  .notice-disconnect {
    position: absolute;
    top: 15px;
    width: 8px;
    height: 8px;
  }
  .notice-disconnect:before, .notice-disconnect:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 8px;
    width: 2px;
    background-color: red;
  }
  .notice-disconnect:before {
    transform: rotate(45deg);
  }
  .notice-disconnect:after {
    transform: rotate(-45deg);
  }
}

.content-wrapper {
  height: 100%;
  margin-left: 230px;
  padding-top: 50px;
  transition: padding, .3s,
              margin .3s;
  min-height: calc(100vh - 101px);
  background-color: #ecf0f5;
  z-index: 800;
}

@media (max-width: 991px) {
  .main-header .navbar-custom-menu a {
    color: inherit;
    background: transparent;
  }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left;
  }
  .navbar-custom-menu .navbar-nav>li {
    float: left;
  }
  .navbar-custom-menu>.navbar-nav>li {
    position: static;
  }
  .navbar-custom-menu .navbar-nav>li>a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
  }
  .navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
    position: absolute;
    right: auto;
    left: auto;
    border: 1px solid #ddd;
    background: #fff;
  }
  .navbar-custom-menu > .navbar-nav > li:nth-last-child(-n+2) .dropdown-menu {
    right: 0
  }
  .navbar-custom-menu > .navbar-nav > li:nth-last-child(4) .dropdown-menu {
    right: 0
  }
}


@media (min-width: 768px) {
  .sidebar-collapse {
    .main-header{
      .logo {
        width: 50px;
        & > .logo-mini {
          display: block;
          margin-left: -15px;
          margin-right: -15px;
          font-size: 18px;
        }
      }
      .navbar {
        margin-left: 50px;
      }
    }
    .content-wrapper {
      margin-left: 0;
      padding-left: 60px;
    }
  }
  .navbar {
    .navbar-custom-menu {
      .navbar-nav {
        .dropdown:nth-last-child(-n+2) {
          .dropdown-menu {
            right: 0
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .main-header {
    .logo, .navbar {
      width: 100%;
      float: none;
    }
    .navbar {
      margin: 0
    }
  }
  .main-sidebar {
    transform: translate(-230px, 0);
    padding-top: 100px;
  }
  .sidebar-collapse {
    .main-sidebar {
      transform: translate(0, 0);
    }
    .content-wrapper {
      margin-left: 0;
    }
  }
  .content-wrapper {
    padding-top: 100px;
    margin-left: 0;
  }
}

