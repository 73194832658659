.main-sidebar {
  background-color: #222d32;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  transition: transform .3s ease-in-out,width .3s ease-in-out,-webkit-transform .3s ease-in-out;
  .sidebar {
    padding-bottom: 10px;
  }
  .sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      overflow: visible;
    }
    .treeview>a {
      outline: none;
      text-decoration: none;
    }
    .treeview>ul.treeview-menu {
      overflow: hidden;
      height: auto;
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
    .treeview.menu-open>ul.treeview-menu {
      overflow: visible;
      height: auto;
    }
    .treeview-menu>li>a {
      padding: 5px 5px 5px 15px;
      display: block;
      font-size: 14px;
      &:hover {
        outline: none;
        text-decoration: none;
      }
    }
    & > li {
      position: relative;
      margin: 0;
      padding: 0;
      & > a {
        position: relative;
        padding: 12px 5px 12px 15px;
        display: block;
      }
    }
    li {
      svg {
        margin-right: 8px;
      }
      a {
        border-left: 3px solid transparent;
        color: #b8c7ce;
      }
      &.menu-open, &:hover {
        & > a {
          color: #fff;
        }
      }
      .treeview-menu {
        margin: 0 1px;
        background: #2c3b41;
        display: none;
        list-style: none;
        padding: 0 0 0 5px;
        &>li {
          margin: 0
        }
        li.active {
          a {
            color: #fff;
          }
        }
        &>ul.treeview-menu {
          overflow: hidden;
          height: auto;
          padding-top: 0!important;
          padding-bottom: 0!important;
        }
      }
      .pull-right-container {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .sidebar-collapse {
    aside.main-sidebar {
      transform: translate(0);
      width: 50px !important;
      z-index: 850;
      .sidebar-menu {
        & > li {
          & > a {
            & > span {
              display: none !important;
            }
          }
          .treeview-menu {
            display: none !important;
          }
          &:hover {
            & > a > span:not(.pull-right) {
              display: block !important;
              position: absolute;
              width: 200px;
              left: 52px;
              top: 3px;
              margin-left: -3px;
              padding: 12px 5px 12px 45px;
              background-color: #222d32;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
            .pull-right-container {
              position: relative !important;
              float: right;
              width: auto !important;
              left: 200px !important;
              top: -22px !important;
              z-index: 900;
              font-size: 0;
              display: none !important;
            }
            .treeview-menu {
              display: block !important;
              position: absolute;
              width: 200px;
              left: 51px;
              top: 44px;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
  }
}